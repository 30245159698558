import React from 'react'

const Events = () => (
  <div>
    <h1>Events</h1>
    <p>accessible to members only</p>
    <p>pull stuff from google calendar maybe?</p>
  </div>
)

export default Events
